


































































import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { Component, Prop } from 'vue-property-decorator';
import Op from 'quill-delta/dist/Op';
import { textToOps } from '@/utils/quill-delta.util';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import {
  PendingDocumentReferences,
  PendingSectionError
} from '@/jbi-shared/types/document.types';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';

@Component({
  components: {
    ListSectionValueEditor,
    SectionError,
    SectionTitleEditor: () =>
      import('@/components/editor/SectionEditor/SectionTitleEditor.vue')
  }
})
export default class StagingReferencesSection extends mixins(ViewModeMixin) {
  @Prop()
  public references!: Op[];
  @Prop()
  public dirtyReferences!: PendingDocumentReferences;

  get referencesSectionTitle() {
    return textToOps('References');
  }

  get referencesErrorMessages() {
    const errorMessages = [];

    /* Add error message for invalid references. */
    for (const reference of this.references) {
      if (this.isInvalid(reference)) {
        errorMessages.push({
          code: PendingSectionError.SECTION_MISSING_INFO,
          message: `Missing information in References`
        });
        break;
      }
    }
    return errorMessages;
  }

  get referencesNoticeMessages() {
    const noticeMessages = this.dirtyReferences.notices || [];

    return noticeMessages;
  }

  public isInvalid(reference: Op) {
    return !reference.insert;
  }
}
